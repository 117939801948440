exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-contract-tsx": () => import("./../../../src/pages/contract.tsx" /* webpackChunkName: "component---src-pages-contract-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-judgement-tsx": () => import("./../../../src/pages/judgement.tsx" /* webpackChunkName: "component---src-pages-judgement-tsx" */),
  "component---src-pages-law-tsx": () => import("./../../../src/pages/law.tsx" /* webpackChunkName: "component---src-pages-law-tsx" */),
  "component---src-pages-news-tsx": () => import("./../../../src/pages/news.tsx" /* webpackChunkName: "component---src-pages-news-tsx" */),
  "component---src-templates-contract-single-tsx": () => import("./../../../src/templates/contract-single.tsx" /* webpackChunkName: "component---src-templates-contract-single-tsx" */),
  "component---src-templates-judgement-single-tsx": () => import("./../../../src/templates/judgement-single.tsx" /* webpackChunkName: "component---src-templates-judgement-single-tsx" */),
  "component---src-templates-law-single-tsx": () => import("./../../../src/templates/law-single.tsx" /* webpackChunkName: "component---src-templates-law-single-tsx" */),
  "component---src-templates-post-single-tsx": () => import("./../../../src/templates/post-single.tsx" /* webpackChunkName: "component---src-templates-post-single-tsx" */)
}

